<script>
import Vue from 'vue';
import env from '../env';
import Gen from '../helper/Gen';
import Croppa from 'vue-croppa';
import moment from 'moment';

/* Vue plugins */
Vue.use(Croppa);
// Vue.component('croppa', () => import(Croppa.component));

import "@plugins/jquery/dist/jquery.min.js"

global.$ = global.jQuery
global.Gen = Gen
moment.locale("en")
global.moment = moment

import "@backend/dist/css/style.min.css"
import "@backend/css/custom.scss"

import "@plugins/extension.js"
// import "@backend/dist/js/custom.min.js"
import "@plugins/jquery-validation/jquery-validation.js"
import "@backend/js/main.js"
// import "@backend/dist/js/perfect-scrollbar.jquery.min.js"

// VueFormWizard
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

Vue.component("ImagePreview",()=>import("@backend/components/ImagePreview.vue"))
Vue.component("BoField",()=>import("@backend/components/BoField.vue"))
Vue.component("SortField",()=>import("@backend/components/SortField.vue"))
Vue.component("StatusLabel",()=>import("@backend/components/StatusLabel.vue"))
Vue.component("FileManager",()=>import("@components/FileManager.vue"))
Vue.component("ImageFilter",()=>import("@forms/ImageFilter.vue"))

export default {
  name:"BoMain",
  async mounted(){

    // Gen.loadCss("/plugins/bootstrap/css/bootstrap.min.css")
    Gen.loadCss("/backend/assets/node_modules/morrisjs/morris.css")
    Gen.loadCss("/backend/assets/dist/css/pages/ecommerce.css")
    Gen.loadCss("/backend/assets/custom_l9.css?v=0.3")
    
    Gen.loadCss("/frontend/css/loader.css?v=0.1")

    await Gen.loadScript("/backend/assets/node_modules/popper/popper.min.js")
    await Gen.loadScript("/backend/assets/node_modules/bootstrap/dist/js/bootstrap.min.js")
    await Gen.loadScript("/backend/assets/dist/js/perfect-scrollbar.jquery.min.js")
    await Gen.loadScript("/backend/assets/dist/js/waves.js")
    await Gen.loadScript("/backend/assets/node_modules/sidebar-nav/dist/sidebar-nav.min.js")
    await Gen.loadScript("/backend/assets/node_modules/sticky-kit-master/dist/sticky-kit.min.js")
    await Gen.loadScript("/backend/assets/node_modules/sparkline/jquery.sparkline.min.js")
    await Gen.loadScript("/backend/assets/node_modules/jquery-sparkline/jquery.sparkline.min.js")
    await Gen.loadScript("/backend/assets/node_modules/raphael/raphael-min.js")

    setTimeout(()=>{      
      Gen.loadScript("/backend/assets/dist/js/custom.min.js?v=0.2")
      this.$root.ready = true
    },2000)

    /* Ready Loaded */
    $(document).ready(()=>{
      this.$root.ready = true
    })

    $("title").text("Backoffice - Cape Discovery");
    $(document).on('keydown', '.form-control', function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false;
      }  
    });
  }
}

</script>
<template>
  <router-view/>
</template>